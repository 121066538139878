import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import COLORS from '@/colors'
import AppBar from '@/components/AppBar'
import Dialog from '@/components/Dialog'
import HandIcon from '@/components/icons/HandIcon'
import LabelProcessingIcon from '@/components/icons/LabelProcessingIcon'
import PackingIcon from '@/components/icons/PackingIcon'
import ScanIcon from '@/components/icons/ScanIcon'
import Loading from '@/components/Loading'
import Spacer from '@/components/Spacer'
import useDebounce from '@/hooks/useDebounce'

import InductionResult, {
  InductionStatus,
  InductionPassResult,
  inductionPassStatuses,
} from './models/Induction'
import useInductions from './useInductions'
import WeightDimensionsMeasureForm from './WeightDimensionsMeasureForm'
import WeightMeasureForm from './WeightMeasureForm'

interface InductionStyle {
  color: string
  icon: React.ReactNode
}

const inductionStyle: Record<InductionStatus, InductionStyle> = {
  label_processing: {
    color: COLORS.greenDark,
    icon: <LabelProcessingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.greenDark} />,
  },
  inspection: {
    color: COLORS.yellowDarkest,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.yellowDarkest} />,
  },
  exception: {
    color: COLORS.redDarkest,
    icon: <CloseIcon sx={{ fontSize: 120 }} htmlColor={COLORS.redDarkest} />,
  },
  call_problem_solver: {
    color: COLORS.blueDarkest,
    icon: <HandIcon sx={{ fontSize: 120 }} htmlColor={COLORS.blueDarkest} />,
  },
  oversize: {
    color: COLORS.failure,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.failure} />,
  },
  forced_inspection: {
    color: COLORS.brownDark,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.brownDark} />,
  },
  single_parcel_xray: {
    color: COLORS.brownDarkest,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.brownDarkest} />,
  },
  high_value: {
    color: COLORS.redNormal,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.redNormal} />,
  },
}

export type MeasurementOption = 'no_measurement' | 'weight_measurement' | 'weight_dims_measurement'

export const measurementOptionLabel: Record<MeasurementOption, string> = {
  no_measurement: 'None',
  weight_measurement: 'Weight',
  weight_dims_measurement: 'Weight + Dimensions',
}

export default function InductionPage() {
  const navigate = useNavigate()
  const { scan } = useInductions()
  const [scanValue, setScanValue] = useState('')
  const scanValueDebounced = useDebounce(scanValue, 300)
  const [inductionResult, setInductionResult] = useState<InductionResult | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isMeasureDialogOpen, setIsMeasureDialogOpen] = useState(false)
  const [measurementOption, setMeasurementOption] = useState<MeasurementOption>('no_measurement')

  function isPassInductionResult(
    inductionResult: InductionResult,
  ): inductionResult is InductionPassResult {
    return ([...inductionPassStatuses] as string[]).includes(inductionResult.status)
  }

  useEffect(() => {
    function handleKeystroke(e: KeyboardEvent) {
      const character = e.key
      !isMeasureDialogOpen && setScanValue((scanValue) => scanValue + character)
    }

    document.addEventListener('keydown', handleKeystroke)
    return () => document.removeEventListener('keydown', handleKeystroke)
  }, [isMeasureDialogOpen])

  useEffect(() => {
    setScanValue('')
    if (scanValueDebounced) {
      setIsLoading(true)
      scan(scanValueDebounced)
        .then((result) => {
          setInductionResult(result)
          if (isPassInductionResult(result) && measurementOption !== 'no_measurement') {
            const hasWeight = result.package.weight.value > 0
            !hasWeight && setIsMeasureDialogOpen(true)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }, [scanValueDebounced])

  function handleMeasureDialogClose() {
    setInductionResult(null)
    setIsMeasureDialogOpen(false)
  }

  function handleSubmitSuccess(inductionResult: InductionPassResult) {
    setInductionResult(inductionResult)
    setIsMeasureDialogOpen(false)
  }

  return (
    <>
      <Stack height="100%">
        <AppBar>
          <ToggleButtonGroup
            value={measurementOption}
            onChange={(_, v: MeasurementOption | null) => !!v && setMeasurementOption(v)}
            color="primary"
            exclusive
          >
            {Object.entries(measurementOptionLabel).map(([option, label]) => (
              <ToggleButton key={option} value={option}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Spacer />
          <Button variant="text" onClick={() => navigate('/')}>
            home
          </Button>
        </AppBar>
        <Stack flex={1} alignItems="center" justifyContent="space-around">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {inductionResult && !isMeasureDialogOpen && (
                <>
                  {isPassInductionResult(inductionResult) ? (
                    <Stack alignItems="center" justifyContent="center">
                      <Typography variant="h1" fontSize={30}>
                        {inductionResult.package.warehouseState.displayName}
                      </Typography>
                      <Typography variant="h2" fontSize={30}>
                        {inductionResult.package.firstMileTrackingNumber}
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography variant="h2" fontSize={30}>
                      {inductionResult.message}
                    </Typography>
                  )}
                  <Stack alignItems="center" justifyContent="center">
                    {inductionStyle[inductionResult.status].icon}
                    <Typography
                      textAlign="center"
                      fontSize={45}
                      fontWeight="bold"
                      color={inductionStyle[inductionResult.status].color}
                    >
                      {inductionResult.label}
                    </Typography>
                  </Stack>
                </>
              )}
              <Stack direction="row" spacing={1} alignItems="center">
                <ScanIcon sx={{ fontSize: inductionResult ? 24 : 40 }} />
                <Typography fontSize={inductionResult ? 20 : 36}>
                  Scan to induct {inductionResult && 'the next '}package
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <Dialog
        open={isMeasureDialogOpen}
        onClose={handleMeasureDialogClose}
        title={`Tracking # ${
          (inductionResult &&
            isPassInductionResult(inductionResult) &&
            inductionResult.package.firstMileTrackingNumber) ??
          ''
        }`}
        maxWidth="sm"
        fullWidth
      >
        {measurementOption === 'weight_dims_measurement' ? (
          <WeightDimensionsMeasureForm
            inductionResult={
              inductionResult && isPassInductionResult(inductionResult) ? inductionResult : null
            }
            onSubmitSuccess={handleSubmitSuccess}
          />
        ) : (
          <WeightMeasureForm
            inductionResult={
              inductionResult && isPassInductionResult(inductionResult) ? inductionResult : null
            }
            onSettled={handleSubmitSuccess}
          />
        )}
      </Dialog>
    </>
  )
}
