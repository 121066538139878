import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'
import { PackageMeasurementsMetadata } from '@/core/models/Package'

import { WeightDimensionsFormData } from './WeightDimensionsForm'
import { WeightFormData } from './WeightForm'

export default function useInductions() {
  const { inductionsGateway } = useGateways()
  const measureWeightDimensionsMutation = useMutation({
    mutationFn: ({
      packageId,
      formData,
      metadata,
    }: {
      packageId: string
      formData: WeightDimensionsFormData
      metadata: PackageMeasurementsMetadata | undefined
    }) => inductionsGateway.measureWeightDimensions(packageId, formData, metadata),
    onError: (reason) => toastApiError(reason),
  })

  const measureWeightMutation = useMutation(
    ({ packageId, formData }: { packageId: string; formData: WeightFormData }) =>
      inductionsGateway.measureWeight(packageId, formData),
    {
      onError: (reason) => toastApiError(reason),
    },
  )

  const markAsUndersizedMutation = useMutation({
    mutationFn: inductionsGateway.markAsUndersized,
  })

  const markAsOversizedMutation = useMutation({
    mutationFn: inductionsGateway.markAsOversized,
  })

  return {
    scan: inductionsGateway.scan,
    measureWeightMutation,
    measureWeightDimensionsMutation,
    markAsUndersizedMutation,
    markAsOversizedMutation,
  }
}
