import InductionResult, { InductionPassResult } from './models/Induction'
import useInductions from './useInductions'
import WeightForm, { WeightFormData } from './WeightForm'

interface WeightMeasureFormProps {
  inductionResult: InductionPassResult | null
  onSettled(inductionResult: InductionResult | undefined): void
}

export default function WeightMeasureForm({ inductionResult, onSettled }: WeightMeasureFormProps) {
  const { measureWeightMutation } = useInductions()

  function handleSubmit(formData: WeightFormData) {
    if (!inductionResult) return
    measureWeightMutation.mutate(
      { packageId: inductionResult.package.id, formData },
      { onSettled: (res) => onSettled(res) },
    )
  }

  return <WeightForm onSubmit={handleSubmit} />
}
