import { CourierReturnDataResponse } from '@/CourierReturn/gateways/api/models/CourierReturn'
import { RepackDataResponse } from '@/gateways/api/models/Repack'
import { ReturnPackageDataResponse } from '@/returnPackage/gateways/api/models/ReturnPackage'
import { OrderItem, OrderSearchPayload } from '@/searchResult/gateways/api/models/Order'

import { ShipFrom } from './ShipFrom'
import { ShipTo } from './ShipTo'

export const warehouseInspectionStates = [
  'dg_inspection_required',
  'pg_inspection_required',
  'battery_inspection_required',
  'hvg_inspection_required',
] as const

export type WarehouseInspectionState = (typeof warehouseInspectionStates)[number]

export type WarehouseState =
  | WarehouseInspectionState
  | 'not_received'
  | 'received'
  | 'over_limit'
  | 'measured'
  | 'dps_confirmed'
  | 'dg_confirmed'
  | 'pg_confirmed'
  | 'label_generated'
  | 'label_failed'
  | 'ready_for_xray'
  | 'xray_completed'
  | 'dropped'
  | 'to_be_destroyed'
  | 'to_be_liquidated'
  | 'ready_for_destroy_liquidate'
  | 'battery_confirmed_compliant'
  | 'battery_confirmed_compliant_with_stickers'
  | 'battery_confirmed_non_compliant'
  | 'ready_for_liquidate_dps'
  | 'courier_returned'
  | 'return_received'
  | 'not_valid'
  | 'inspection_requested'

type ScanPayload = {
  barcode: string
}

export type ScreeningFlagStatus = 'to_inspect' | 'dismissed' | 'confirmed'
export type ScreeningFlagCategory = 'dangerous' | 'prohibited' | 'battery' | 'high_value'

export interface ScreeningFlag {
  status: ScreeningFlagStatus
  category: ScreeningFlagCategory
  denied_party_name: null
  denied_party_type: null
  destination_country_name: null
  keyword: string
  operating_procedures: string
  screening_guidelines: string
  source: null
  comments: string
  item_title: string
}

export type ScanSearchPayload = ScanPayload | OrderSearchPayload

export const weightUnits = ['lb', 'kg'] as const
export type WeightUnit = (typeof weightUnits)[number]

export const dimensionUnits = ['in', 'cm'] as const
export type DimensionUnit = (typeof dimensionUnits)[number]

export const batteryWeightUnits = ['lb', 'g', 'oz'] as const
export type BatteryWeightUnit = (typeof batteryWeightUnits)[number]

export interface BatteryWeight {
  value: number
  unit: BatteryWeightUnit
}

interface PackageWeight {
  value: number | null
  unit: WeightUnit | null
}

export interface MeasurePayload {
  package: {
    weight: {
      unit: WeightUnit
      value: number
    }
    dimensions: {
      length: number | null
      width: number | null
      height: number | null
      unit: string | null
    }
    metadata: {
      measurement_id: number | null
      scanned_on: string | null
    }
  }
}

export interface MeasurementsResponse {
  measurements: {
    weight_unit: WeightUnit
    weight: number
    dimension_unit: DimensionUnit
    length: number
    width: number
    height: number
    metadata: {
      measurement_id: number
      scanned_on: string
    }
  }
}

export type BatteryInspectionResult =
  | 'shippable'
  | 'shippable_with_labels'
  | 'shippable_with_stickers'
  | 'unshippable'
  | 'no_batteries'
  | 'shippable_with_stickers'

export type BatteryInspectionSticker =
  | 'cargo_aircraft_only'
  | 'section_ii_pi967'
  | 'section_ii_pi966'
  | 'section_ii_pi970'
  | 'section_ii_pi969'
  | 'dangerous_goods_class_9_un3480'
  | 'dangerous_goods_class_9_un3091'
  | 'dangerous_goods_class_9_un3090'
  | 'dangerous_goods_class_9_un3481'
  | 'lithium_battery_un3480'
  | 'lithium_battery_un3091'
  | 'lithium_battery_un3481'
  | 'lithium_battery_un3090'

export type BatteryUnshippableReason =
  | 'not_secure_from_movement'
  | 'not_secure_from_activation'
  | 'classification'
  | 'overweight'
  | 'excess_batteries_or_cells'
  | 'mixed_packing_material'

export interface BatteryInspectionClassification {
  name: string
  instructions: string[]
}

export interface BatteryInspectionOutcomeResponse {
  result: BatteryInspectionResult
  shipping_document_instructions: string[]
  labels: BatteryInspectionSticker[]
  unshippable_reasons: BatteryUnshippableReason[]
  classifications: BatteryInspectionClassification[]
}

export type LabelState =
  | 'not_created'
  | 'processing'
  | 'generated'
  | 'no_rates'
  | 'failed'
  | 'technical_failed'
  | 'reported'
  | 'voided'
  | 'void_failed'

export type InspectionSource =
  | 'xray'
  | 'random'
  | 'forced'
  | 'single_parcel_xray'
  | 'courier_return'
export type InspectionStatus = 'requested' | 'categorized' | 'confirmed' | 'dismissed'
export type InspectionCategory = '' | 'dangerous' | 'prohibited' | 'battery'
export type InspectionCategoryDefined = Exclude<InspectionCategory, ''>

export interface InspectionResponse {
  id: string
  source: InspectionSource
  status: InspectionStatus
  category: InspectionCategory
}

export type BinType = 'courier' | 'destroy_liquidate'
export type CourierIdentifier = 'Asendia' | 'Pitney Bowes' | 'DHL eCommerce'

export interface PackageBin {
  barcode: string
  name: string
  display_name: CourierIdentifier
  type: BinType
}

export interface PackageDimensions {
  length: number | null
  width: number | null
  height: number | null
  unit: DimensionUnit | null
}

export interface PackageDataResponse {
  id: string
  ebay_package_id: string
  easyship_shipment_id: string
  first_mile_tracking_number: string
  items: OrderItem[]
  warehouse_state: WarehouseState
  warehouse_state_display_name: string
  last_mile_tracking_number: string | null
  scan_received: string
  last_station_id: number
  last_mile_courier_name: string | null
  last_mile_courier_umbrella_name: string | null
  flagged_for?: ScreeningFlagCategory[]
  screening_flags?: ScreeningFlag[]
  inspections: InspectionResponse[]
  battery_inspection_outcome: BatteryInspectionOutcomeResponse | null
  label_state: LabelState
  evtn: string
  ship_from: ShipFrom
  ship_to: ShipTo
  bin_to_place_in: PackageBin | null
  weight: PackageWeight | null
  dimensions: PackageDimensions | null
  combined_shipment: boolean
  courier_return: CourierReturnDataResponse | null
  repack: RepackDataResponse | null
  multipack: boolean
  package_tags: packageTags[]
  processed_courier_return_count: number
}

export interface packageTags {
  slug: InspectionSource
  description: string
}
interface ScanPackageResponse {
  type: 'Package'
  attributes: PackageDataResponse
}

interface ScanReturnPackageResponse {
  type: 'ReturnPackage'
  attributes: ReturnPackageDataResponse
}

export interface ScanResponse {
  result: {
    data: (ScanPackageResponse | ScanReturnPackageResponse)[]
  }
}

export interface PackagesResponse {
  packages: PackageDataResponse[]
}

export interface PackageResponse {
  package: PackageDataResponse
}

export interface DismissFlagPayload {
  comments: string
  category: ScreeningFlagCategory
}

export interface ConfirmFlagPayload {
  comments: string
  category: ScreeningFlagCategory
}
export interface ConfirmHighValueGoodsPayload {
  zendesk_id: string
  category: ScreeningFlagCategory
}

export const batteryClassifications = [
  'lithium_battery',
  'lithium_cell',
  'alkaline',
  'lead_acid',
  'nicd',
  'nimh',
  'silver_oxide',
  'zinc_air',
  'zinc_carbon',
  'not_specified',
] as const

export type BatteryClassification = (typeof batteryClassifications)[number]

export const batteryPackings = [
  'itself_alone',
  'packed_with_equipment',
  'contained_in_equipment',
] as const

export type BatteryPacking = (typeof batteryPackings)[number]

export const BatteryTypesIon = ['lithium_ion_cell', 'lithium_ion_battery'] as const
export const BatteryTypesMetal = ['lithium_metal_cell', 'lithium_metal_battery'] as const
export const BatteryTypesNonLithium = [
  'non_lithium_batteries_not_restricted',
  'non_lithium_batteries_restricted',
] as const
export const batteryTypes = [...BatteryTypesIon, ...BatteryTypesMetal, ...BatteryTypesNonLithium]

export type BatteryTypeIon = (typeof BatteryTypesIon)[number]
export type BatteryTypeMetal = (typeof BatteryTypesMetal)[number]
export type BatteryTypeNonLithium = (typeof BatteryTypesNonLithium)[number]
export type BatteryType = (typeof batteryTypes)[number]

export interface BatteryCellPayload {
  watt_hours: number | null
  lithium_content: number | null
  total_weight: number | null
  weight_unit: BatteryWeightUnit
}

interface BatteryInspectionItemPayload {
  item_id: string
  item_description: string
  battery_present: boolean
  battery_classification: BatteryClassification | null
  battery_classification_comment: string | null
  requires_compliance_review: boolean | null
  battery_packaging: BatteryPacking | null
  battery_type: BatteryType | null
  batteries_or_cells: BatteryCellPayload[]
  is_equipment_and_battery_secured_from_movement: boolean | null
}

export interface BatteryInspectionPayload {
  forms: BatteryInspectionItemPayload[]
  is_parcel_equipped_to_prevent_accidental_activation: boolean | null
}

export interface MarkAsReceivedPayload {
  search_fields: (keyof OrderSearchPayload)[]
}

interface PartialPackagesError {
  message: string
  failed_barcodes: string[]
}

interface PartialPackagesSuccess {
  id: string
}

export interface PartialSuccessPackagesResponse {
  error: PartialPackagesError | null
  succeeded_packages: PartialPackagesSuccess[]
}

export interface BatchMarkForInspectionPayload {
  last_mile_tracking_numbers: string[]
  inspection_source: InspectionSource
}

export interface PlaceInBinPayload {
  barcode: string
}
