import { UseMutationResult, useMutation } from '@tanstack/react-query'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import COLORS from '@/colors'
import { toastApiError } from '@/components/Toastify'
import { useFeatureFlags } from '@/contexts/featureFlags'
import { useGateways } from '@/contexts/gateways'
import { isApiError } from '@/core/models/ApiError'
import Inspection from '@/core/models/Inspection'
import Package, { PackageMeasurementsMetadata } from '@/core/models/Package'
import { Repack } from '@/core/models/Repack'
import { InspectionCategoryDefined, InspectionSource } from '@/gateways/api/models/Package'

import { SearchFormData } from '../ScanPage/ScanPage'
import { MeasureFormData } from './MeasureForm'
import { BatteryInspectionFormData } from './PackageInspection/BatteryInspection'

export type BinSlug = 'courier' | 'xray_courier' | 'inspection' | 'destroy_liquidate' | 'exception'

interface BinInfo {
  name: string
  bgColor: string
  textColor: string
}

export function getBinInfo(pack: Package, binSlug: BinSlug): BinInfo {
  const binRecord: Record<BinSlug, BinInfo> = {
    courier: {
      name: pack.lastMileCourierName,
      bgColor: COLORS.greenLight,
      textColor: COLORS.greenDark,
    },
    xray_courier: {
      name: `X-Ray Passed: ${pack.lastMileCourierName}`,
      bgColor: COLORS.greenLight,
      textColor: COLORS.greenDark,
    },
    inspection: {
      name: 'Inspection',
      bgColor: COLORS.yellowLight,
      textColor: COLORS.yellowDark,
    },
    destroy_liquidate: {
      name: 'Destroy/Liquidate',
      bgColor: COLORS.redLight,
      textColor: COLORS.redDark,
    },
    exception: {
      name: 'Exception',
      bgColor: COLORS.blueLight,
      textColor: COLORS.blueDark,
    },
  }
  return binRecord[binSlug]
}

interface PackageContextData {
  pack: Package
  bin: BinInfo | null
  setBin: (slug: BinSlug) => Promise<void>
  currentInspection: Inspection | undefined
  isLoading: boolean
  loadingMessage: string
  markAsReceived: (searchParams: SearchFormData) => Promise<void>
  measureWeightDimensions: (
    formData: MeasureFormData,
    metadata: PackageMeasurementsMetadata | undefined,
  ) => Promise<void>
  requestLabel: () => Promise<void>
  dismissFlag: (comments: string) => Promise<void>
  confirmFlag: (comments: string) => Promise<void>
  submitBatteryInspection(formData: BatteryInspectionFormData): Promise<void>
  markForDestruction: (reason: string) => Promise<void>
  markForLiquidation: (reason: string) => Promise<void>
  printBarcode: () => Promise<void>
  reprintLabel: () => Promise<void>
  requestInspection: (source: InspectionSource) => Promise<void>
  dismissCurrentInspection(comments?: string): Promise<void>
  categorizeCurrentInspection(category: InspectionCategoryDefined): Promise<void>
  confirmCurrentInspection(comments: string): Promise<void>
  classifyBattery(formData: BatteryInspectionFormData): Promise<void>
  placeInBin(barcode: string): Promise<void>
  resetToMeasureStep(): Promise<void>
  markAsOverLimit(): Promise<void>
  confirmHighValueGoods: (zendeskId: string) => Promise<void>
  forceDestroyLiquidate(): Promise<void>
  markAsCourierReturn(): Promise<void>
  requestRepack: UseMutationResult<Repack, unknown, { packageId: string; upc: string }, unknown>
  skipRepack: UseMutationResult<void, unknown, void, unknown>
  updatePackage: (pack: Partial<Package>) => void
  submitZendeskTickets: UseMutationResult<void, unknown, string, unknown>
}

const PackageContext = createContext<PackageContextData | undefined>(undefined)

interface PackageProviderProps {
  initialPackage: Package
  children: React.ReactNode
}

function PackageProvider({ initialPackage, children }: PackageProviderProps) {
  const { isFeatureEnabled } = useFeatureFlags()
  const { packagesGateway, inspectionsGateway } = useGateways()
  const [pack, setPack] = useState(initialPackage)
  const [binSlug, setBinSlug] = useState<BinSlug | null>(getInitialBin())
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const currentInspection = pack.inspections.find(
    (inspection) => inspection.status !== 'confirmed' && inspection.status !== 'dismissed',
  )

  const bin = binSlug ? getBinInfo(pack, binSlug) : null

  function getInitialBin(): BinSlug | null {
    const isLabelProcessing =
      pack.warehouseState.value === 'measured' && pack.labelState !== 'not_created'
    const isLabelFailed =
      pack.labelState === 'failed' && pack.warehouseState.value !== 'ready_for_destroy_liquidate'

    if (isLabelProcessing || isLabelFailed) {
      return 'exception'
    }
    return null
  }

  useEffect(() => {
    if (pack.warehouseState.value === 'measured') {
      if (pack.flaggedFor.length > 0) {
        requireInspection()
      } else if (currentInspection) {
        requestInspection(currentInspection.source)
      } else if (
        pack.labelState === 'not_created' &&
        (pack.courierReturn?.processed_at || !!pack.dimensions.height)
      ) {
        requestLabel()
      }
    }

    if (pack.warehouseState.value === 'label_generated') {
      printLabel()
    }

    if (
      pack.warehouseState.value === 'dg_confirmed' ||
      pack.warehouseState.value === 'pg_confirmed' ||
      pack.warehouseState.value === 'battery_confirmed_non_compliant' ||
      pack.warehouseState.value === 'over_limit' ||
      pack.warehouseState.value === 'dps_confirmed'
    ) {
      requireDestroyLiquidate()
    }

    if (pack.warehouseState.value === 'battery_confirmed_compliant') {
      requestLabel()
    }
  }, [pack])

  function updatePackage(newPackage: Partial<Package>) {
    setPack((pack) => ({
      ...pack,
      ...newPackage,
    }))
  }

  function withLoading<T>(loadingText: string, func: Promise<T>): Promise<T> {
    setIsLoading(true)
    setLoadingMessage(loadingText)
    return func.finally(() => setIsLoading(false))
  }

  async function markAsReceived(searchParams: SearchFormData): Promise<void> {
    await withLoading(
      'Marking as Received',
      packagesGateway.markAsReceived(pack.id, searchParams),
    ).then(setPack)
  }

  async function measureWeightDimensions(
    formData: MeasureFormData,
    metadata: PackageMeasurementsMetadata | undefined,
  ): Promise<void> {
    await withLoading(
      'Updating Measurements',
      packagesGateway.measureWeightDimensions(pack.id, formData, metadata),
    ).then(setPack)
  }

  async function requestLabel(): Promise<void> {
    await withLoading('Requesting Label', packagesGateway.requestLabel(pack.id))
      .then(setPack)
      .catch((reason) => {
        if (isApiError(reason) && reason.error.code === 'resource_not_saved_error') {
          setBinSlug('exception')
        }
      })
  }

  async function printLabel(): Promise<void> {
    await withLoading('Printing Label', packagesGateway.printLabel(pack.id))
      .then(setPack)
      .then(() => setBinSlug('courier'))
  }

  async function requireInspection(): Promise<void> {
    await withLoading('Marking as requiring inspection', packagesGateway.requireInspection(pack.id))
      .then(setPack)
      .then(() => setBinSlug('inspection'))
  }

  async function requireDestroyLiquidate(): Promise<void> {
    await withLoading(
      'Marking as requiring destroy and liquidate',
      packagesGateway.requireDestroyLiquidate(pack.id).then(setPack),
    )
  }

  async function forceDestroyLiquidate(): Promise<void> {
    await withLoading(
      'Marking as forcing destroy and liquidate',
      packagesGateway.forceDestroyLiquidate(pack.id).then(setPack),
    )
  }

  async function dismissFlag(comments: string): Promise<void> {
    await withLoading(
      'Dismissing flag',
      packagesGateway.dismissFlag(pack.id, { comments, category: pack.flaggedFor[0] }),
    ).then(setPack)
  }

  async function confirmHighValueGoods(zendeskId: string): Promise<void> {
    await withLoading(
      'Confirming flag',
      packagesGateway.confirmFlag(pack.id, {
        category: pack.flaggedFor[0],
        zendesk_id: zendeskId,
      }),
    )
      .then(setPack)
      .catch((err) => toastApiError(err))
  }

  async function confirmFlag(comments: string): Promise<void> {
    await withLoading(
      'Confirming flag',
      packagesGateway.confirmFlag(pack.id, {
        comments,
        category: pack.flaggedFor[0],
      }),
    ).then(setPack)
  }

  async function submitBatteryInspection(formData: BatteryInspectionFormData): Promise<void> {
    await withLoading(
      'Submitting battery inspection',
      packagesGateway.submitBatteryInspection(pack.id, formData),
    )
      .then(setPack)
      .catch((err) => toastApiError(err))
  }

  async function markForDestruction(reason: string): Promise<void> {
    await withLoading(
      'Marking for destruction',
      packagesGateway.markForDestruction(pack.id, reason),
    )
      .then(setPack)
      .catch((err) => toastApiError(err))
  }

  async function markForLiquidation(reason: string): Promise<void> {
    await withLoading(
      'Marking for liquidation',
      packagesGateway.markForLiquidation(pack.id, reason),
    )
      .then(setPack)
      .catch((err) => toastApiError(err))
  }

  async function requestInspection(source: InspectionSource): Promise<void> {
    await withLoading(
      'Marking as requiring inspection',
      packagesGateway.requestInspection(pack.id, source),
    )
      .then(setPack)
      .then(() => setBinSlug('inspection'))
  }

  async function printBarcode(): Promise<void> {
    await packagesGateway
      .printBarcode(pack.id)
      .then(() => {
        toast.success(`Barcode printed successfully`)
      })
      .catch((err) => toastApiError(err))
  }

  async function reprintLabel(): Promise<void> {
    await packagesGateway.reprintLabel(pack.id).catch((err) => toastApiError(err))
  }

  async function dismissCurrentInspection(comments = ''): Promise<void> {
    if (!currentInspection) return Promise.reject('There is no inspection in progress')
    await withLoading(
      'Dismissing inspection',
      inspectionsGateway.dismiss(currentInspection.id, comments),
    ).then((newPack) => {
      setPack(newPack)
      if (newPack.warehouseState.value === 'xray_completed') {
        setBinSlug('xray_courier')
      }
    })
  }

  async function categorizeCurrentInspection(category: InspectionCategoryDefined): Promise<void> {
    if (!currentInspection) return Promise.reject('There is no inspection in progress')
    await withLoading(
      `Marking for ${category} inspection`,
      inspectionsGateway.categorize(currentInspection.id, category),
    ).then(setPack)
  }

  async function confirmCurrentInspection(comments: string): Promise<void> {
    if (!currentInspection) return Promise.reject('There is no inspection in progress')
    await withLoading(
      'Confirming inspection',
      inspectionsGateway.confirm(currentInspection.id, comments),
    )
      .then(setPack)
      .then(requireDestroyLiquidate)
  }

  async function classifyBattery(formData: BatteryInspectionFormData): Promise<void> {
    if (!currentInspection) return Promise.reject('There is no inspection in progress')
    await withLoading(
      'Submitting battery inspection',
      inspectionsGateway.classifyBattery(currentInspection.id, formData),
    )
      .then(setPack)
      .catch((err) => toastApiError(err))
  }

  async function placeInBin(barcode: string): Promise<void> {
    await packagesGateway.placeInBin(pack.id, barcode)
  }

  async function resetToMeasureStep(): Promise<void> {
    await withLoading(
      'Resetting package to measurement step',
      packagesGateway.resetToMeasureStep(pack.id),
    ).then(setPack)
  }

  async function markAsOverLimit(): Promise<void> {
    await withLoading(
      'Marking package as over limit',
      packagesGateway.markAsOverLimit(pack.id),
    ).then(setPack)
  }
  async function markAsCourierReturn(): Promise<void> {
    await withLoading(
      'Marking package as courier return',
      packagesGateway.markAsCourierReturn(pack.id),
    ).then(setPack)
  }

  const requestRepack = useMutation({
    mutationFn: async ({ packageId, upc }: { packageId: string; upc: string }) =>
      await withLoading('Requesting repack', packagesGateway.requestRepack(packageId, upc)),
    onSuccess: () => {
      requestLabel()
    },
    onError: (reason) => toastApiError(reason),
  })

  const skipRepack = useMutation({
    mutationFn: async () =>
      await withLoading('Skipping repack', packagesGateway.skipRepack(pack.id)),
    onSuccess: () => {
      if (pack.flaggedFor.length > 0) {
        requireInspection()
      } else if (currentInspection) {
        requestInspection(currentInspection.source)
      } else {
        requestLabel()
      }
    },
    onError: (reason) => toastApiError(reason),
  })

  const submitZendeskTickets = useMutation({
    mutationFn: (zendeskTicketId: string) =>
      packagesGateway.submitZendeskTickets(pack.id, zendeskTicketId),
    onError: (reason) => toastApiError(reason),
  })

  async function setBin(slug: BinSlug): Promise<void> {
    await setBinSlug(slug)
  }

  const data: PackageContextData = {
    pack,
    bin,
    setBin,
    currentInspection,
    isLoading,
    loadingMessage,
    markAsReceived,
    measureWeightDimensions,
    requestLabel,
    dismissFlag,
    confirmFlag,
    submitBatteryInspection,
    markForDestruction,
    markForLiquidation,
    printBarcode,
    reprintLabel,
    requestInspection,
    dismissCurrentInspection,
    categorizeCurrentInspection,
    confirmCurrentInspection,
    classifyBattery,
    placeInBin,
    resetToMeasureStep,
    markAsOverLimit,
    confirmHighValueGoods,
    forceDestroyLiquidate,
    markAsCourierReturn,
    requestRepack,
    skipRepack,
    updatePackage,
    submitZendeskTickets,
  }

  return <PackageContext.Provider value={data}>{children}</PackageContext.Provider>
}

function usePackage() {
  const packageContext = useContext(PackageContext)

  if (packageContext === undefined) {
    throw new Error('usePackage must be used within a PackageProvider')
  }

  return packageContext
}

export { PackageProvider, usePackage }
